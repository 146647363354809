import { Brand as BrandEnum } from 'models/Brand';

export type SelectField = {
  value: string;
  label: string;
};

export const SALUTATIONS: SelectField[] = [
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mx', label: 'Mx' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Mr', label: 'Mr' },
];

export const LEAD_SOURCES: SelectField[] = [
  { value: 'PCL Dine My Way', label: 'PCL Dine My Way' },
  { value: 'PCL OceanReady', label: 'PCL OceanReady' },
  { value: 'PCL Casino', label: 'PCL Casino' },
  { value: 'PCL Effy', label: 'PCL Effy' },
  { value: 'PCL Porsche', label: 'PCL Porsche' },
  { value: 'PCL Caribbean', label: 'PCL Caribbean' },
  { value: 'PCL Coral', label: 'PCL Coral' },
  { value: 'PCL Crown', label: 'PCL Crown' },
  { value: 'PCL Diamond', label: 'PCL Diamond' },
  { value: 'PCL Discovery', label: 'PCL Discovery' },
  { value: 'PCL Emerald', label: 'PCL Emerald' },
  { value: 'PCL Enchanted', label: 'PCL Enchanted' },
  { value: 'PCL Grand', label: 'PCL Grand' },
  { value: 'PCL Island', label: 'PCL Island' },
  { value: 'PCL Majestic', label: 'PCL Majestic' },
  { value: 'PCL Regal', label: 'PCL Regal' },
  { value: 'PCL Royal', label: 'PCL Royal' },
  { value: 'PCL Ruby', label: 'PCL Ruby' },
  { value: 'PCL Sapphire', label: 'PCL Sapphire' },
  { value: 'PCL Sky', label: 'PCL Sky' },
  { value: 'PCL Sun', label: 'PCL Sun' },
  { value: 'GBK - Fire and Ice', label: 'GBK - Fire and Ice' },
  { value: 'Amazon', label: 'Amazon' },
  { value: 'Caribbean Conquest', label: 'Caribbean Conquest' },
  { value: '1-Renter (CVCAY)', label: '1-Renter (CVCAY)' },
  { value: '2-Owner (CVCAY)', label: '2-Owner (CVCAY)' },
  { value: 'Past Renter', label: 'Past Renter' },
  { value: 'Paid Media', label: 'Paid Media' },
  { value: 'PCA Group', label: 'PCA Group' },
  { value: 'SECRETS THE VINE EXIT', label: 'SECRETS THE VINE EXIT' },
  { value: 'CW-Evergreen', label: 'CW-Evergreen' },
  { value: 'UCF Evergreen', label: 'UCF Evergreen' },
  { value: 'UVC VVIP', label: 'UVC VVIP' },
  { value: 'UVC Employee VIP', label: 'UVC Employee VIP' },
  { value: 'UVC Employee', label: 'UVC Employee' },
  { value: 'Pueblo Bonito Exit - NO PURCHASE', label: 'Pueblo Bonito Exit - NO PURCHASE' },
];

export const customerSpecificSearchBrands: string[] = [
  BrandEnum.MedallionClassMarket,
  BrandEnum.FutureCruisePackages,
  BrandEnum.FutureCruisePackagesCanada,
  BrandEnum.FutureCruisePackagesUk,
  BrandEnum.ClubPrincess,
];
