export enum RouteKey {
  Customers = 'customers',
  CustomersList = 'customersList',
  CustomersDetails = 'customersDetails',
  Sales = 'sales',
  CreateSalesOrder = 'createSalesOrder',
  SalesView = 'salesView',
  Quotes = 'quotes',
  SalesOrders = 'salesOrders',
  OrderDetails = 'orderDetails',
  Payments = 'payments',
  PaymentPlans = 'paymentPlans',
  PaymentItems = 'paymentItems',
  AllTransactions = 'allTransactions',
  Products = 'products',
  ProductList = 'productList',
  CreateProduct = 'createProduct',
  ImportProductRates = 'importProductRates',
  ProductDetails = 'productDetails',
  Marketing = 'marketing',
  MarketingView = 'marketingView',
  PromoCodes = 'promoCodes',
  PromoCodeDefinitionDetails = 'promoCodeDefinitionDetails',
  PromotionPromoCodeDefinitionDetails = 'promotionPromoCodeDefinitionDetails',
  AddPromoCodeDefinition = 'addPromoCodeDefinition',
  Promotions = 'promotions',
  AddPromotion = 'addPromotion',
  PromotionDetails = 'promotionDetails',
  ComingSoon = 'comingSoon',
  Reports = 'reports',
  SalesReport = 'salesReport',
  CustomerReport = 'customerReport',
  MarketingReport = 'marketingReport',
  TransactionsReport = 'transactionsReport',
  Settings = 'settings',
  Developers = 'developers',
  Suppliers = 'suppliers',
  Tags = 'tags',
  BulkMemberEnrollment = 'BulkMemberEnrollment',
  Locations = 'locations',
  Dashboard = 'dashboard',
  Team = 'team',
  StyleGuide = 'styleGuide',
  MatchingHotels = 'matchingHotels',
  AssetsLiabilities = 'assetsLiabilities',
  DelinquencyReport = 'delinquencyReport',
  TransactionDetails = 'transactionDetails',
  Reservations = 'reservations',
}
