import { Navigate, Route, Routes } from 'react-router-dom';

import { AccessControlPermissions } from 'apiAccess/accessControlAccess';
import { ReactComponent as CreditCardIcon } from 'assets/icons/creditCard.svg';
import { ReactComponent as DataTransferIcon } from 'assets/icons/dataTransfer.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as LoveIcon } from 'assets/icons/love.svg';
import { ReactComponent as ModuleIcon } from 'assets/icons/module.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';
import { ReactComponent as PerformanceIcon } from 'assets/icons/performance.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as TerminalIcon } from 'assets/icons/terminal.svg';
import { DynamicBreadcrumbs } from 'components/_common/DynamicBreadcrumbs';
import { PageHeader } from 'components/_common/PageHeader';
import { LayoutConfig } from 'components/layout/LayoutConfig';
import { AgentLogin } from 'components/views/agency/AgentLogin';
import { AgentsList } from 'components/views/agency/AgentsList';
import { ComingSoon } from 'components/views/comingSoon/ComingSoon';
import { CustomerDetailView } from 'components/views/customers/components/CustomerDetailView';
import { CustomersListView } from 'components/views/customers/CustomersListView';
import { Dashboard } from 'components/views/dashboard/Dashboard';
import { MarketingDashboard } from 'components/views/marketing/MarketingDashboard';
import { MatchingHotels } from 'components/views/matching/MatchingHotels';
import { BulkMembersEnrollment } from 'components/views/members/BulkMembersEnrollmentView';
import { CreateProduct } from 'components/views/products/CreateProduct';
import { Details as ProductDetails } from 'components/views/products/Details';
import { ImportProductRates } from 'components/views/products/ImportProductRates';
import { Products } from 'components/views/products/Products';
import { productTypes } from 'components/views/products/ProductTypeConfig';
import { AddPromoCodeDefinitionView } from 'components/views/promoCodes/AddPromoCodeDefinitionView';
import { PromoCodeDefinitionDetailView } from 'components/views/promoCodes/PromoCodeDefinitionDetailView';
import { PromoCodeDefinitionListView } from 'components/views/promoCodes/PromoCodeDefinitionListView';
import { AddPromotionView } from 'components/views/promotions/AddPromotionView';
import { PromotionDetailView } from 'components/views/promotions/PromotionDetailView';
import { PromotionsListView } from 'components/views/promotions/PromotionsListView';
import { AssetsAndLiabilitiesReport } from 'components/views/reports/AssetsLiabilities/AssetsAndLiabilitiesReport';
import { CustomerReport } from 'components/views/reports/CustomerReport/CustomerReport';
import { DelinquencyReport } from 'components/views/reports/DelinquencyReport/DelinquencyReport';
import { MarketingReport } from 'components/views/reports/MarketingReport/MarketingReport';
import { SalesReport } from 'components/views/reports/SalesReport/SalesReport';
import { TransactionReport } from 'components/views/reports/TransactionReport/TransactionReport';
import { Reservations } from 'components/views/reservations/Reservations';
import { CreateSalesOrderView } from 'components/views/sales/CreateSalesOrderView';
import { OrderDetails } from 'components/views/salesOrders/OrderDetails';
import { Orders } from 'components/views/salesOrders/Orders';
import { StyleGuide } from 'components/views/styleGuide/StyleGuide';
import { TagsView } from 'components/views/tags/Tags';
import { TransactionDetails } from 'components/views/transactions/TransactionDetails';
import { Transactions } from 'components/views/transactions/Transactions';
import appConfig from 'config/appConfig';
import { RouteKey } from 'models/RouteKey';
import { RoutesPaths } from 'models/RoutePath';

import GuardedRoute from './GuardedRoute';

export interface AppRoute {
  key: RouteKey;
  path: string;
  routePath?: string;
  relativePath?: string;
  label: string;
  component?: any;
  sidebarKey?: string;
  icon?: JSX.Element;
  insideElements?: AppRoute[];
  defaultPathProps?: any;
  disabled?: boolean;
  layoutConfig?: LayoutConfig;
  permissions?: AccessControlPermissions[];
  breadcrumb?: any;
  isAppRoot?: boolean;
}

const customersRoutes: AppRoute[] = [
  {
    key: RouteKey.Customers,
    path: RoutesPaths[RouteKey.Customers],
    routePath: '/customers/*',
    relativePath: '/',
    label: 'Customers',
    component: <CustomersListView />,
    layoutConfig: {
      showPartnerSelect: true,
      showAgencySelect: true,
      showInSidebar: true,
      isSidebarLinkEnabled: true,
    },
    icon: <PeopleIcon height="18px" />,
    permissions: ['Customers:View', 'Customers:Manage'],
    insideElements: [
      {
        key: RouteKey.CustomersList,
        sidebarKey: RouteKey.Customers,
        path: RoutesPaths[RouteKey.CustomersList],
        routePath: '/customers/*',
        relativePath: '/',
        label: 'Customers',
        component: <CustomersListView />,
        layoutConfig: { showPartnerSelect: true, showAgencySelect: true, showInSidebar: false },
        icon: <PeopleIcon height="18px" />,
        permissions: ['Customers:View', 'Customers:Manage'],
        breadcrumb: 'Customers',
      },
      {
        key: RouteKey.CustomersDetails,
        sidebarKey: RouteKey.Customers,
        path: RoutesPaths[RouteKey.CustomersDetails],
        routePath: '/customers/*',
        relativePath: ':id',
        label: 'CustomersDetails',
        component: <CustomerDetailView />,
        permissions: ['Customers:View'],
        layoutConfig: {
          showPartnerSelect: true,
          disablePartnerSelect: true,
          showAgencySelect: true,
          showInSidebar: false,
        },
        breadcrumb: DynamicBreadcrumbs,
      },
    ],
  },
];

const programSalesRoutes: AppRoute[] = [
  {
    key: RouteKey.Sales,
    label: 'Sales',
    path: RoutesPaths[RouteKey.Sales],
    routePath: '/sales/*',
    relativePath: '/',
    component: <Orders />,
    layoutConfig: { isSidebarLinkEnabled: false, showInSidebar: true, showPartnerSelect: true, showAgencySelect: true },
    icon: <CreditCardIcon height="18px" />,
    breadcrumb: 'Sales',
    permissions: ['Sales:View', 'Sales:Manage', 'Sales.ProgramSales:View', 'Sales.ProgramSales:Manage'],
    insideElements: [
      {
        key: RouteKey.SalesView,
        label: 'Sales',
        path: RoutesPaths[RouteKey.SalesView],
        routePath: '/sales/*',
        relativePath: '/',
        component: <Orders />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: false,
        },
        permissions: ['Sales.Orders.List:View'],
        breadcrumb: 'Sales Orders',
      },
      {
        key: RouteKey.CreateSalesOrder,
        sidebarKey: RouteKey.CreateSalesOrder,
        label: 'Create Sales Order',
        path: RoutesPaths[RouteKey.CreateSalesOrder],
        routePath: '/sales/new/*',
        relativePath: '/',
        component: <CreateSalesOrderView />,
        layoutConfig: { showPartnerSelect: true, showAgencySelect: true, showInSidebar: false, fullscreenLayout: true },
        icon: <CreditCardIcon height="18px" />,
        permissions: ['Sales:View', 'Sales:Manage', 'Sales.ProgramSales:View', 'Sales.ProgramSales:Manage'],
      },
      {
        key: RouteKey.Quotes,
        sidebarKey: RouteKey.Sales,
        path: RoutesPaths[RouteKey.Quotes],
        routePath: '/sales/quotes/*',
        relativePath: 'quotes',
        label: 'Quotes',
        component: <ComingSoon />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: true,
        },
        permissions: ['Sales:View', 'Sales.ProgramSales:View'],
        breadcrumb: 'Quotes',
      },
      {
        key: RouteKey.SalesOrders,
        sidebarKey: RouteKey.Sales,
        path: RoutesPaths[RouteKey.SalesOrders],
        routePath: '/sales/orders/*',
        relativePath: 'orders',
        label: 'Sales Orders',
        component: <Orders />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: true,
        },
        permissions: ['Sales.Orders.List:View'],
        breadcrumb: 'Sales Orders',
      },
      {
        key: RouteKey.Reservations,
        sidebarKey: RouteKey.Sales,
        path: RoutesPaths[RouteKey.Reservations],
        routePath: '/sales/reservations/*',
        relativePath: 'reservations',
        label: 'Reservations',
        component: <Reservations />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: true,
        },
        permissions: ['Sales.Orders.List:View'],
        breadcrumb: 'Reservations',
      },
      {
        key: RouteKey.OrderDetails,
        path: RoutesPaths[RouteKey.OrderDetails],
        routePath: '/sales/order/*',
        relativePath: ':orderNumber',
        label: 'Order Details',
        component: <OrderDetails />,
        layoutConfig: {
          showPartnerSelect: true,
          disablePartnerSelect: true,
          showInSidebar: false,
        },
        permissions: ['Sales:View', 'Sales.Orders.List:View'],
        breadcrumb: 'Items',
      },
      {
        key: RouteKey.PaymentItems,
        sidebarKey: RouteKey.Sales,
        path: RoutesPaths[RouteKey.PaymentItems],
        routePath: '/sales/items/*',
        relativePath: 'items',
        label: 'Items',
        component: <ComingSoon />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: true,
        },
        permissions: ['Sales:View', 'Sales.ProgramSales:View'],
        breadcrumb: 'Items',
      },
      {
        key: RouteKey.Payments,
        sidebarKey: RouteKey.Sales,
        path: RoutesPaths[RouteKey.Payments],
        routePath: '/sales/payments/*',
        relativePath: 'payments',
        label: 'Payments',
        component: <ComingSoon />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: true,
        },
        permissions: ['Sales:View', 'Sales.ProgramSales:View'],
        breadcrumb: 'Payments',
      },
      {
        key: RouteKey.PaymentPlans,
        sidebarKey: RouteKey.Sales,
        path: RoutesPaths[RouteKey.PaymentPlans],
        routePath: '/sales/payment-plans/*',
        relativePath: 'payment-plans',
        label: 'Payment Plans',
        component: <ComingSoon />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: true,
        },
        permissions: ['Sales:View', 'Sales.ProgramSales:View'],
        breadcrumb: 'Payment Plans',
      },
      {
        key: RouteKey.AllTransactions,
        sidebarKey: RouteKey.Sales,
        path: RoutesPaths[RouteKey.AllTransactions],
        routePath: '/transactions/*',
        relativePath: 'transactions',
        label: 'All Transactions',
        component: <Transactions />,
        layoutConfig: {
          showPartnerSelect: true,
          showInSidebar: true,
        },
        permissions: ['Transactions.List:View'],
        breadcrumb: 'Transactions',
      },
      {
        key: RouteKey.TransactionDetails,
        path: RoutesPaths[RouteKey.TransactionDetails],
        routePath: '/transaction/*',
        relativePath: ':id',
        label: 'Transaction Details',
        component: <TransactionDetails />,
        layoutConfig: {
          showPartnerSelect: false,
          showInSidebar: false,
        },
        permissions: ['Transactions.List:View'],
        breadcrumb: 'Transactions',
      },
    ],
  },
];

const productsRoutes: AppRoute[] = [
  {
    key: RouteKey.Products,
    label: 'Products',
    path: RoutesPaths[RouteKey.Products],
    routePath: '/products/list/*',
    component: <Products />,
    layoutConfig: { showInSidebar: true },
    icon: <ModuleIcon height="18px" />,
    defaultPathProps: {
      type: productTypes[0].code,
    },
    permissions: ['Products:View', 'Products:Manage'],
    insideElements: [
      {
        key: RouteKey.ProductList,
        label: 'Products List',
        path: RoutesPaths[RouteKey.ProductList],
        component: <Products />,
        layoutConfig: { showInSidebar: true },
        defaultPathProps: {
          type: productTypes[0].code,
        },
        permissions: ['Products:View', 'Products:Manage'],
        breadcrumb: 'Products',
      },
      {
        key: RouteKey.CreateProduct,
        label: 'Create Product',
        path: RoutesPaths[RouteKey.CreateProduct],
        component: <CreateProduct />,
        layoutConfig: { showInSidebar: true },
        permissions: ['Products:View', 'Products:Manage'],
        breadcrumb: 'Create Product',
      },
      {
        key: RouteKey.ImportProductRates,
        label: 'Import Product Rates',
        path: RoutesPaths[RouteKey.ImportProductRates],
        component: <ImportProductRates />,
        layoutConfig: { showInSidebar: true },
        permissions: ['Products:View', 'Products:Manage'],
        breadcrumb: 'Import Product Rates',
      },
      {
        key: RouteKey.ProductDetails,
        sidebarKey: RouteKey.ProductList,
        label: 'Product',
        path: RoutesPaths[RouteKey.ProductDetails],
        routePath: '/products/*',
        component: <ProductDetails />,
        layoutConfig: { showInSidebar: true },
        permissions: ['Products:View', 'Products:Manage'],
        breadcrumb: 'Product Details',
      },
    ],
  },
];

const marketingCodesRoutes: AppRoute[] = [
  {
    key: RouteKey.Marketing,
    label: 'Marketing',
    path: RoutesPaths[RouteKey.Marketing],
    routePath: '/marketing/*',
    relativePath: '/',
    icon: <LoveIcon height="18px" />,
    permissions: ['Marketing.Promotions:View'],
    layoutConfig: {
      showPartnerSelect: true,
      showInSidebar: true,
      isSidebarLinkEnabled: true,
    },
    component: <MarketingDashboard />,
    breadcrumb: 'Marketing',
    insideElements: [
      {
        key: RouteKey.MarketingView,
        label: 'Marketing',
        path: RoutesPaths[RouteKey.MarketingView],
        routePath: '/marketing/*',
        relativePath: '/',
        permissions: ['Marketing.Promotions:View'],
        layoutConfig: { showPartnerSelect: true, showInSidebar: false },
        component: <MarketingDashboard />,
        breadcrumb: 'Marketing',
      },
      {
        key: RouteKey.PromoCodes,
        sidebarKey: RouteKey.PromoCodes,
        label: 'Promo Codes',
        path: RoutesPaths[RouteKey.PromoCodes],
        permissions: ['Marketing.Promotions:View'],
        layoutConfig: { showPartnerSelect: true, disablePartnerSelect: false, showInSidebar: true },
        component: <PromoCodeDefinitionListView />,
        breadcrumb: 'Promo Codes',
      },
      {
        key: RouteKey.AddPromoCodeDefinition,
        sidebarKey: RouteKey.PromoCodes,
        path: RoutesPaths[RouteKey.AddPromoCodeDefinition],
        label: 'Add Promo Code Definition',
        permissions: ['Marketing.Promotions:View', 'Marketing.Promotions:Manage'],
        layoutConfig: { showPartnerSelect: true, disablePartnerSelect: false, showInSidebar: false },
        component: <AddPromoCodeDefinitionView />,
        breadcrumb: 'New Promo Code',
      },
      {
        key: RouteKey.PromoCodeDefinitionDetails,
        sidebarKey: RouteKey.PromoCodes,
        path: RoutesPaths[RouteKey.PromoCodeDefinitionDetails],
        routePath: '/marketing/promo-codes/*',
        relativePath: ':id',
        label: 'Promo Code Definition',
        permissions: ['Marketing.Promotions:View'],
        layoutConfig: { showPartnerSelect: true, disablePartnerSelect: true, showInSidebar: false },
        component: <PromoCodeDefinitionDetailView />,
        breadcrumb: DynamicBreadcrumbs,
      },
      {
        key: RouteKey.PromotionPromoCodeDefinitionDetails,
        sidebarKey: RouteKey.Promotions,
        path: RoutesPaths[RouteKey.PromotionPromoCodeDefinitionDetails],
        routePath: '/marketing/promotion/*',
        relativePath: ':promotionId/promo-codes/:id/:redirectUrl',
        label: 'Promo Code Definition',
        permissions: ['Marketing.Promotions:View'],
        layoutConfig: { showPartnerSelect: true, disablePartnerSelect: true, showInSidebar: false },
        component: <PromoCodeDefinitionDetailView />,
        breadcrumb: DynamicBreadcrumbs,
      },
      {
        key: RouteKey.Promotions,
        sidebarKey: RouteKey.Promotions,
        path: RoutesPaths[RouteKey.Promotions],
        label: 'Promotions',
        permissions: ['Marketing.Promotions:View'],
        layoutConfig: { showPartnerSelect: true, disablePartnerSelect: false, showInSidebar: true },
        component: <PromotionsListView />,
        breadcrumb: 'Promotions',
      },
      {
        key: RouteKey.AddPromotion,
        sidebarKey: RouteKey.Promotions,
        path: RoutesPaths[RouteKey.AddPromotion],
        label: 'Add Promotion',
        permissions: ['Marketing.Promotions:View', 'Marketing.Promotions:Manage'],
        layoutConfig: { showPartnerSelect: true, disablePartnerSelect: false, showInSidebar: false },
        component: <AddPromotionView />,
        breadcrumb: 'New Promotion',
      },
      {
        key: RouteKey.PromotionDetails,
        sidebarKey: RouteKey.Promotions,
        path: RoutesPaths[RouteKey.PromotionDetails],
        routePath: '/marketing/promotions/*',
        relativePath: ':id',
        label: 'Promotion Details',
        permissions: ['Marketing.Promotions:View'],
        layoutConfig: { showPartnerSelect: true, disablePartnerSelect: true, showInSidebar: false },
        component: <PromotionDetailView />,
        breadcrumb: DynamicBreadcrumbs,
      },
      {
        key: RouteKey.ComingSoon,
        label: 'Coming Soon',
        path: RoutesPaths[RouteKey.ComingSoon],
        component: <ComingSoon />,
        layoutConfig: { showInSidebar: false },
        breadcrumb: 'Coming Soon',
        permissions: ['Marketing.Promotions:View'],
      },
    ],
  },
];

const reportsRoutes: AppRoute[] = [
  {
    key: RouteKey.Reports,
    label: 'Reports',
    path: RoutesPaths[RouteKey.Reports],
    icon: <PerformanceIcon height="18px" />,
    permissions: ['Reports:View', 'Reports.SalesReports:View', 'Reports.DelinquencyReport:View'],
    layoutConfig: { addMenuSpacing: true, showInSidebar: true },
    insideElements: [
      {
        key: RouteKey.SalesReport,
        label: 'Sales Report',
        path: RoutesPaths[RouteKey.SalesReport],
        component: <SalesReport />,
        permissions: ['Reports.SalesReports:View'],
        layoutConfig: { showPartnerSelect: true, showInSidebar: true },
        breadcrumb: 'Sales Report',
      },
      {
        key: RouteKey.CustomerReport,
        label: 'Customer Report',
        path: RoutesPaths[RouteKey.CustomerReport],
        component: <CustomerReport />,
        permissions: ['Reports.CustomerReport:View'],
        layoutConfig: { showPartnerSelect: true, showInSidebar: true },
        breadcrumb: 'Customer Report',
      },
      {
        key: RouteKey.MarketingReport,
        label: 'Marketing Report',
        path: RoutesPaths[RouteKey.MarketingReport],
        component: <MarketingReport />,
        permissions: ['Reports.CustomerReport:View'],
        layoutConfig: { showPartnerSelect: true, showInSidebar: true },
        breadcrumb: 'Marketing Report',
      },
      {
        key: RouteKey.TransactionsReport,
        label: 'Transactions Report',
        path: RoutesPaths[RouteKey.TransactionsReport],
        component: <TransactionReport />,

        permissions: ['Reports.SalesReports:View'],
        //TODO update this once the permissions are added on the BE
        // permissions: ['Reports.TransactionReport:View'],
        layoutConfig: { showPartnerSelect: false, showInSidebar: true },
        breadcrumb: 'Transactions Report',
      },
      {
        key: RouteKey.AssetsLiabilities,
        label: 'Assets & Liabilities Report',
        path: RoutesPaths[RouteKey.AssetsLiabilities],
        component: <AssetsAndLiabilitiesReport />,
        permissions: ['Reports.SalesReports:View'],
        layoutConfig: { showPartnerSelect: true, showInSidebar: true },
        breadcrumb: 'Assets & Liabilities Report',
      },
      {
        key: RouteKey.DelinquencyReport,
        label: 'Delinquency Report',
        path: RoutesPaths[RouteKey.DelinquencyReport],
        component: <DelinquencyReport />,
        permissions: ['Reports.DelinquencyReport:View'],
        layoutConfig: { showPartnerSelect: false, showInSidebar: true },
        breadcrumb: 'Delinquency Report',
      },
    ],
  },
];
const suppliersRoutes: AppRoute[] = [
  {
    key: RouteKey.Suppliers,
    label: 'Suppliers',
    path: RoutesPaths[RouteKey.Suppliers],
    layoutConfig: { showInSidebar: true },
    component: <ComingSoon />,
    icon: <DataTransferIcon height="18px" />,
    permissions: ['Products:View'],
    breadcrumb: 'Suppliers',
  },
];

const developersRoutes: AppRoute[] = [
  {
    key: RouteKey.Developers,
    label: 'Developers',
    path: RoutesPaths[RouteKey.Developers],
    layoutConfig: { showInSidebar: true },
    component: <ComingSoon />,
    icon: <TerminalIcon height="18px" />,
    permissions: ['Settings:View', 'Settings:Manage'],
    breadcrumb: 'Developers',
  },
];

const settingsRoutes: AppRoute[] = [
  {
    key: RouteKey.Settings,
    label: 'Administration',
    path: RoutesPaths[RouteKey.Settings],
    layoutConfig: { showInSidebar: true },
    icon: <SettingsIcon height="18px" />,
    permissions: [
      'Settings:View',
      'Settings:Manage',
      'Settings.Tags:View',
      'Settings.Tags:Manage',
      'Customers:Manage:BulkEnroll',
    ],
    insideElements: [
      {
        key: RouteKey.Tags,
        label: 'Tags',
        path: RoutesPaths[RouteKey.Tags],
        component: <TagsView />,
        layoutConfig: { showInSidebar: true },
        permissions: ['Settings.Tags:View'],
        breadcrumb: 'Tags',
      },
      {
        key: RouteKey.Locations,
        label: 'Locations',
        path: RoutesPaths[RouteKey.Locations],
        component: <PageHeader title="Locations" testId="Locations" />,
        layoutConfig: { showInSidebar: true },
        disabled: true,
      },
      {
        key: RouteKey.BulkMemberEnrollment,
        sidebarKey: RouteKey.BulkMemberEnrollment,
        path: RoutesPaths[RouteKey.BulkMemberEnrollment],
        routePath: '/settings/bulk-member-enrollment/*',
        relativePath: '/',
        label: 'Bulk Member Enrollment',
        component: <BulkMembersEnrollment />,
        permissions: ['Customers:Manage:BulkEnroll'],
        layoutConfig: { showPartnerSelect: true, showAgencySelect: false, addMenuSpacing: true, showInSidebar: true },
      },
    ],
  },
];

export const routes: AppRoute[] = [
  {
    key: RouteKey.Dashboard,
    label: 'Home',
    path: RoutesPaths[RouteKey.Dashboard],
    component: <Dashboard />,
    layoutConfig: { showInSidebar: true, showPartnerSelect: true },
    icon: <HomeIcon height="18px" />,
    permissions: ['Dashboard:View', 'Agency.Dashboard:View'],
    breadcrumb: 'Dashboard',
    isAppRoot: true,
  },
  ...customersRoutes,
  ...programSalesRoutes,
  ...suppliersRoutes,
  ...marketingCodesRoutes,
  ...productsRoutes,
  ...reportsRoutes,
  ...developersRoutes,
  ...settingsRoutes,
  {
    key: RouteKey.Team,
    label: 'Team',
    path: RoutesPaths[RouteKey.Team],
    component: <AgentsList />,
    layoutConfig: { showInSidebar: true },
    icon: <PeopleIcon height="18px" />,
    permissions: ['Agency.AgentList:View'],
  },
];

// DEVELOPMENT ONLY: Style Guide is only accessible from DEV.
if (!appConfig.prodMode) {
  routes.push({
    key: RouteKey.StyleGuide,
    label: 'Style Guide',
    path: RoutesPaths[RouteKey.StyleGuide],
    component: <StyleGuide />,
    layoutConfig: { showInSidebar: false },
  });
}

if (appConfig.matchingTool) {
  routes.push({
    key: RouteKey.MatchingHotels,
    label: 'Matching Hotels',
    path: RoutesPaths[RouteKey.MatchingHotels],
    component: <MatchingHotels />,
    layoutConfig: { showInSidebar: false },
  });
}

export const buildPath = (route: AppRoute): string => {
  if (route.defaultPathProps && route.path) {
    let newPath = route.path;
    Object.keys(route.defaultPathProps).forEach(
      (key) => (newPath = newPath.replace(`:${key}`, route.defaultPathProps[key])),
    );
    return newPath;
  }

  return route.path;
};

const SwitchRoutes = () => {
  return (
    <>
      <Routes>
        {routes
          .filter((r) => r.path != null && r.insideElements == null)
          .map((route) => {
            return (
              <Route
                key={route.key}
                path={route.path}
                element={
                  <GuardedRoute routePermissions={route.permissions} layout={route.layoutConfig}>
                    {route.component}
                  </GuardedRoute>
                }
              />
            );
          })}
        {routes
          .filter((r) => r.insideElements != null)
          .map((route) =>
            route?.insideElements?.map((route) => {
              return (
                <Route
                  key={route.key}
                  path={route.path}
                  element={
                    <GuardedRoute routePermissions={route.permissions} layout={route.layoutConfig}>
                      {route.component}
                    </GuardedRoute>
                  }
                />
              );
            }),
          )}
        <Route path="/agent-login" element={<AgentLogin />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export const RenderRoutes = () => {
  return <SwitchRoutes />;
};
