import qs from 'qs';

import { GridFilterModel } from '@mui/x-data-grid';

export enum FiltersOperatorsType {
  'dateBetween' = 'dateBetween',
  'nameEquals' = 'nameEquals',
  amountComparison = 'amountComparison',
  'is' = 'is',
  'contains' = 'contains',
}

export enum FiltersComparisonType {
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
}

export const loadFiltersFromQueryParams = (parsed: qs.ParsedQs) => {
  const filters: GridFilterModel = { items: [] };

  Object.entries(parsed).forEach(([key, objValue]) => {
    if (!objValue) {
      return;
    }

    const value = objValue as Record<string, any>;

    if (value.from) {
      filters.items.push({
        field: key,
        operator: FiltersOperatorsType.dateBetween,
        value: {
          start: value.from,
          end: value.to ?? undefined,
        },
      });
      return;
    }

    if (value.gt || value.lt) {
      filters.items.push({
        field: key,
        operator: FiltersOperatorsType.amountComparison,
        value: {
          amount: value.gt || value.lt,
          comparison: value.gt ? FiltersComparisonType.greaterThan : FiltersComparisonType.lessThan,
        },
      });
    }

    if (value.firstName && value.lastName) {
      filters.items.push({
        field: key,
        operator: FiltersOperatorsType.nameEquals,
        value: {
          firstName: value.firstName,
          lastName: value.lastName,
        },
      });
    }

    if (value[FiltersOperatorsType.is] || value[FiltersOperatorsType.contains]) {
      filters.items.push({
        field: key,
        operator: value[FiltersOperatorsType.is] ? FiltersOperatorsType.is : FiltersOperatorsType.contains,
        value: value[FiltersOperatorsType.is] || value[FiltersOperatorsType.contains],
      });
    }
  });

  return filters;
};
