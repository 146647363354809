import { Fragment } from 'react';

import { List, ListItem, Typography } from '@mui/material';
import { CancellationPolicy } from '@one/api-models/lib/Admin/Sales/Travel/Experience/CancellationPolicy';
import { VendorCancellationReason } from '@one/api-models/lib/Admin/Sales/Travel/Experience/VendorCancellationReason';

interface ExperienceCancellationInfoProps {
  cancellationPolicy?: CancellationPolicy;
}

export const ExperienceCancellationInfo = ({ cancellationPolicy }: ExperienceCancellationInfoProps) => {
  const fullRefundable = cancellationPolicy?.customerConditions?.find((refund) => refund.refundPercentage === 100);
  const nonRefundable =
    !fullRefundable && cancellationPolicy?.customerConditions?.find((refund) => refund.refundPercentage === 0);

  return (
    <>
      {cancellationPolicy?.description && (
        <Typography my={1}>
          <span dangerouslySetInnerHTML={{ __html: escapeHTML(cancellationPolicy.description) }} />
        </Typography>
      )}
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          ml: 1,
          pt: 0,
          '& .MuiListItem-root': {
            display: 'list-item',
            py: 0.5,
          },
        }}
      >
        {nonRefundable && (
          <ListItem>
            This experience is non-refundable and cannot be changed for any reason. If you cancel or ask for an
            amendment, the amount you paid will not be refunded.
          </ListItem>
        )}
        {fullRefundable && fullRefundable.endOffsetInDays === 1 && (
          <>
            <ListItem>
              For a full refund, you must cancel at least 24 hours before the experience’s start time.
            </ListItem>
            <ListItem>
              If you cancel less than 24 hours before the experience’s start time, the amount you paid will not be
              refunded.
            </ListItem>
            <ListItem>
              Any changes made less than 24 hours before the experience’s start time will not be accepted.
            </ListItem>
          </>
        )}
        {fullRefundable && fullRefundable.endOffsetInDays > 1 && (
          <>
            <ListItem>
              {`For a full refund, you must cancel at least ${fullRefundable.endOffsetInDays} days before the experience’s start time.`}
            </ListItem>
            <ListItem>
              {`If you cancel less than ${fullRefundable.endOffsetInDays} days before the experience’s start time, the amount you paid will not be refunded.`}
            </ListItem>
            <ListItem>
              {`Any changes made less than ${fullRefundable.endOffsetInDays} days before the experience’s start time will not be accepted.`}
            </ListItem>
          </>
        )}
        {fullRefundable && <ListItem>Cut-off times are based on the experience’s local time.</ListItem>}
        {cancellationPolicy?.vendorConditions?.map((vendorCondition, index) => (
          <Fragment key={index}>
            {vendorCondition.reason === VendorCancellationReason.SevereWeather && (
              <ListItem>
                This experience requires good weather. If it’s canceled due to poor weather, you’ll be offered a
                different date or a full refund.
              </ListItem>
            )}
            {vendorCondition.reason === VendorCancellationReason.MinimumParticipantsNotMet && (
              <ListItem>
                This experience requires a minimum number of travelers. If it’s canceled because the minimum isn’t met,
                you’ll be offered a different date/experience or a full refund.
              </ListItem>
            )}
          </Fragment>
        ))}
      </List>
    </>
  );
};
const escapeHTML = (html: string) => {
  return html
    ?.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};
