import { RouteKey } from './RouteKey';

export const RoutesPaths: Record<RouteKey, string> = {
  [RouteKey.Dashboard]: '/',
  [RouteKey.Customers]: '/customers',
  [RouteKey.CustomersList]: '/customers',
  [RouteKey.CustomersDetails]: '/customers/:id',
  [RouteKey.Sales]: '/sales',
  [RouteKey.SalesView]: '/sales',
  [RouteKey.CreateSalesOrder]: 'sales/new',
  [RouteKey.Quotes]: '/sales/quotes',
  [RouteKey.SalesOrders]: '/sales/orders',
  [RouteKey.Reservations]: '/sales/reservations',
  [RouteKey.OrderDetails]: '/sales/order/:orderNumber',
  [RouteKey.Payments]: '/sales/payments',
  [RouteKey.PaymentPlans]: '/sales/payment-plans',
  [RouteKey.PaymentItems]: '/sales/items',
  [RouteKey.AllTransactions]: '/transactions',
  [RouteKey.TransactionDetails]: '/transaction/:id',
  [RouteKey.Products]: '/products',
  [RouteKey.ProductList]: '/products/list/:type',
  [RouteKey.CreateProduct]: '/products/createProduct',
  [RouteKey.ImportProductRates]: '/products/importProductRates',
  [RouteKey.ProductDetails]: '/products/:type/:id',
  [RouteKey.Marketing]: '/marketing',
  [RouteKey.MarketingView]: '/marketing',
  [RouteKey.PromoCodes]: '/marketing/promo-codes',
  [RouteKey.AddPromoCodeDefinition]: '/marketing/promo-codes/add',
  [RouteKey.PromoCodeDefinitionDetails]: '/marketing/promo-codes/:id',
  [RouteKey.PromotionPromoCodeDefinitionDetails]: '/marketing/promotions/:promotionId/promo-codes/:id/:redirectUrl',
  [RouteKey.Promotions]: '/marketing/promotions',
  [RouteKey.AddPromotion]: '/marketing/promotions/add',
  [RouteKey.PromotionDetails]: '/marketing/promotions/:id',
  [RouteKey.ComingSoon]: 'marketing/coming-soon',
  [RouteKey.Reports]: '/reports',
  [RouteKey.SalesReport]: '/reports/sales-report',
  [RouteKey.CustomerReport]: '/reports/customer-report',
  [RouteKey.MarketingReport]: '/reports/marketing-report',
  [RouteKey.TransactionsReport]: '/reports/transactions-report',
  [RouteKey.AssetsLiabilities]: '/reports/assets-and-liabilities-report',
  [RouteKey.DelinquencyReport]: '/reports/delinquency-report',
  [RouteKey.Suppliers]: '/suppliers',
  [RouteKey.Developers]: '/developers',
  [RouteKey.Settings]: '/settings',
  [RouteKey.Tags]: '/settings/tags',
  [RouteKey.Locations]: '/settings/locations',
  [RouteKey.BulkMemberEnrollment]: '/settings/bulk-member-enrollment',
  [RouteKey.Team]: '/team',
  [RouteKey.StyleGuide]: '/style-guide',
  [RouteKey.MatchingHotels]: '/matching-hotels',
};
